import get from 'lodash.get';
import styled from 'styled-components';

import { BackgroundImage } from '@atoms/BackgroundImage';

const getAttrTestId = props => ({
  'data-testid': get(props, 'data-testid'),
});

const BackgroundWrapper = styled(BackgroundImage).attrs(props => ({
  height: '180px',
  mobileHeight: '100px',
  tabletHeight: '100px',
  overlayColor: props.theme.color.c400,
  showOnMobile: false,
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
`;

const BackgroundDimmer = styled.div.attrs(getAttrTestId)`
  background-color: ${props => props.theme.color.black};
  bottom: 0;
  display: block;
  left: 0;
  opacity: 0.6;
  position: absolute;
  right: 0;
  top: 0;
`;

const DescriptionWrapper = styled.div.attrs(getAttrTestId)`
  background-color: ${props => props.theme.color.white};
  margin: 0 auto;
  max-width: 100%;
  padding: 50px 15px;
  text-align: center;

  @media ${props => props.theme.query.desktop} {
    max-width: 60%;
  }
`;

const Title = styled.h1.attrs(getAttrTestId)`
  ${props => props.theme.typo.displayM};
  color: ${props => props.theme.color.white};
  position: relative;
  text-align: center;
  text-transform: uppercase;

  @media ${props => props.theme.query.desktop} {
    ${props => props.theme.typo.displayXL};
  }
`;

const Wrapper = styled.section.attrs(getAttrTestId)``;

export {
  BackgroundDimmer,
  BackgroundWrapper,
  DescriptionWrapper,
  Title,
  Wrapper,
};
