// Based on solution here: https://github.com/gatsbyjs/gatsby/issues/2470

import { TYPE_IMAGE } from '@infra/gatsby/types';
import PropTypes from 'prop-types';
import React from 'react';

import { Content, FakeBackgroundImage, Parent } from './styledComponents';

const BackgroundImage = props => {
  const {
    image,
    title,
    height,
    mobileHeight,
    tabletHeight,
    showOnMobile,
    showOnTablet,
    overlayColor,
    children,
    className,
    testId,
    onClick,
  } = props;

  return (
    <Parent overlayColor={overlayColor} data-testid={testId} onClick={onClick}>
      <FakeBackgroundImage
        image={image}
        title={title}
        alt={title}
        height={height}
        mobileHeight={mobileHeight}
        tabletHeight={tabletHeight}
        showOnMobile={showOnMobile}
        showOnTablet={showOnTablet}
        fadeIn={false}
      />
      <Content className={className}>{children}</Content>
    </Parent>
  );
};

BackgroundImage.propTypes = {
  image: TYPE_IMAGE.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.string,
  mobileHeight: PropTypes.string,
  tabletHeight: PropTypes.string,
  showOnMobile: PropTypes.bool,
  showOnTablet: PropTypes.bool,
  overlayColor: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  testId: PropTypes.string,
  onClick: PropTypes.func,
};

BackgroundImage.defaultProps = {
  height: '100%',
  mobileHeight: '100%',
  tabletHeight: '100%',
  showOnMobile: true,
  showOnTablet: true,
  overlayColor: 'transparent',
  children: null,
  className: null,
  testId: null,
  onClick: null,
};

export { BackgroundImage };
