import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const Parent = styled.div`
  position: relative;
  background-color: ${({ overlayColor }) => overlayColor};
`;

export const FakeBackgroundImage = styled(GatsbyImage)`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ height }) => height};
  z-index: 0;

  & > div {
    max-width: 100% !important;
  }

  & > div:first-child > img {
    object-fit: cover !important;
    object-position: 0% 0% !important;
    font-family: 'object-fit: cover !important; object-position: 0% 0% !important;';
  }

  @media ${props => props.theme.query.mobile} {
    & > picture {
      ${props => (props.showOnMobile ? '' : 'display: none;')}
    }

    height: ${({ mobileHeight }) => mobileHeight};
  }

  @media ${props => props.theme.query.tablet} {
    & > picture {
      ${props => (props.showOnTablet ? '' : 'display: none;')}
    }

    height: ${({ tabletHeight }) => tabletHeight};
  }
`;

export const Content = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
`;
