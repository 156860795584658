import { Link as LinkBase } from '@atoms/link';
import styled from 'styled-components';

import { Content, PageContainer } from '@molecules/Containers';

const Hr = styled.hr`
  height: 2px;
  margin: 0px;
  background-color: ${props => props.theme.color.g300};
  border: 0px;
`;

const Link = styled(LinkBase)`
  color: ${props => props.theme.color.p300};
`;

const Wrapper = styled(Content)`
  padding: 30px 0;
`;

export { PageContainer, Hr, Link, Wrapper };
