import { useTranslation } from '@infra/i18n';
import { graphql, useStaticQuery } from 'gatsby';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';

import { HeroPanel } from '@organisms/HeroPanel';

const StaticSiteHero = props => {
  const { title } = props;
  const { t } = useTranslation();

  const imageData = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "default-static-page-header.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 1920)
        }
      }
    }
  `);

  const heroImage = get(imageData, 'desktop.childImageSharp.gatsbyImageData');

  return <HeroPanel title={t(title)} backgroundImage={heroImage} />;
};

StaticSiteHero.propTypes = {
  title: PropTypes.string.isRequired,
};

export { StaticSiteHero };
