import PropTypes from 'prop-types';
import React from 'react';

import {
  BackgroundDimmer,
  BackgroundWrapper,
  DescriptionWrapper,
  Title,
  Wrapper,
} from './styled-components';

const HeroPanel = props => {
  const { backgroundImage, title, description, showDimmer } = props;

  return (
    <Wrapper data-testid="HeroPanel">
      <BackgroundWrapper
        image={backgroundImage}
        title={title}
        testId="HeroPanel-Background"
      >
        {showDimmer && <BackgroundDimmer />}
        <Title data-testid="HeroPanel-Title">{title}</Title>
      </BackgroundWrapper>

      {description && (
        <DescriptionWrapper data-testid="HeroPanel-Description">
          {description}
        </DescriptionWrapper>
      )}
    </Wrapper>
  );
};

HeroPanel.propTypes = {
  backgroundImage: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  showDimmer: PropTypes.bool,
};

HeroPanel.defaultProps = {
  backgroundImage: {},
  description: null,
  showDimmer: false,
};

export { HeroPanel };
