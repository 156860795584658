import { useTranslation } from '@infra/i18n';
import React from 'react';

import { Hr, Link, PageContainer, Wrapper } from './styled-components';

const PrivacyPolicyContent = () => {
  const { t } = useTranslation('privacyPolicyPage');

  return (
    <PageContainer>
      <Wrapper>
        <h2>{t('privacyPolicyConcerning')}</h2>
        <p>
          <i>{t('byUsingOr')}</i>
        </p>
        <p>{t('shipaFreight')}</p>
        <p>
          {t('thisPolicyDescribes1')}
          <Link to="/">www.shipafreight.com</Link>
          {t('thisPolicyDescribes2')}
        </p>
        <p>{t('thePolicyApplies')}</p>
        <Hr />
        <h2>{t('doesShipaFreightCollect')}</h2>
        <p>{t('youCanVisitMost')}</p>
        <p>{t('beforeUsingAnyOf')}</p>
        <p>{t('shipaFreightAlsoCollects')}</p>
        <p>{t('ourWebsiteMayContain')}</p>
        <Hr />
        <h2>{t('whatPersonalDataDoes')}</h2>
        <p>{t('forThePurposesDetailedHerein')}</p>
        <p>
          <strong>{t('logData')}</strong>
          {t('thisInformationOftenConsists')}
        </p>
        <p>
          <strong>{t('ipAddresses')}</strong>
          {t('weCollectIPAddresses')}
        </p>
        <p>
          <strong>{t('userAndThirdParty')}</strong>
          {t('weCollectPersonalData')}
        </p>
        <p>
          <strong>{t('cookies')}</strong>
          {t('cookiesAreUniqueIdentifiers')}
        </p>
        <p>
          <strong>{t('pixelTechnologies')}</strong>
          {t('weMayAlsoUse')}
        </p>
        <p>
          <strong>{t('financialInformation')}</strong>
          {t('forthePurposesDetailedHereinafter')}
        </p>
        <Hr />
        <h2>{t('howDoWeUseYour')}</h2>
        <p>{t('shipaFreightMayUse')}</p>
        <ul>
          <li>{t('toManageItsContractual')}</li>
          <li>{t('toImplementTransactionsOr')}</li>
        </ul>
        <p>{t('forPursuingItsLegitimate')}</p>
        <ul>
          <li>{t('toHelpCreateA')}</li>
          <li>{t('toProtectTheSecurity')}</li>
          <li>{t('forSystemAdministrationPurposes')}</li>
          <li>{t('toLetYouKnow')}</li>
          <li>{t('toProtectShipaFreights')}</li>
          <li>{t('toPerformBusinessAnalytics')}</li>
          <li>{t('toProvideYouWith')}</li>
        </ul>
        <p>{t('lastlyShipaFreightMay')}</p>
        <Hr />
        <h2>{t('doWeImplementCookies')}</h2>
        <p>{t('weMayUseCookies')}</p>
        <p>
          {t('shipaFreightMayImplement1')}
          <Link to="mailto:marketing@shipafreight.com">
            marketing@shipafreight.com
          </Link>
          {t('shipaFreightMayImplement2')}
        </p>
        <p>{t('weDoConductEmail')}</p>
        <Hr />
        <h2>{t('howLongDoesShipa')}</h2>
        <p>{t('shipaFreightWillRetain')}</p>
        <Hr />
        <h2>{t('howDoesShipaFreight')}</h2>
        <p>{t('shipaFreightImplementsAppropriate')}</p>
        <Hr />
        <h2>{t('doesShipaFreightShare')}</h2>
        <p>{t('inOrderToPursue')}</p>
        <p>{t('shipaFreightTakesAppropriate')}</p>
        <Hr />
        <h2>{t('whatAreMyRights')}</h2>
        <p>{t('youAreEntitledTo')}</p>
        <ul>
          <li>{t('requestAccessToYour')}</li>
          <li>{t('requestTheRectificationOf')}</li>
          <li>{t('requestTheErasureOf')}</li>
          <li>{t('requestTheRestrictionOf')}</li>
          <li>{t('receiveYourPersonalData')}</li>
          <li>{t('lodgeAComplaintWith')}</li>
        </ul>
        <p>{t('shouldYouBeWilling')}</p>
        <Hr />
        <h2>{t('areChildrenPermittedTo')}</h2>
        <p>{t('shipaFreightDoesNot')}</p>
        <Hr />
        <h2>{t('whatAboutOtherWebsites')}</h2>
        <p>{t('theInclusionOfAny')}</p>
        <Hr />
        <h2>{t('whatIfShipaFreight')}</h2>
        <p>{t('thisPolicyReflectsOur')}</p>
        <Hr />
        <h2>{t('howCanIContact')}</h2>
        <p>
          {t('ifYouHaveQuestions1')}
          <Link to="mailto:marketing@shipafreight.com">
            marketing@shipafreight.com
          </Link>
          {t('ifYouHaveQuestions2')}
        </p>
        <p>
          <i>
            {t('copyrightShipaE', {
              year: new Date().getFullYear(),
            })}
          </i>
        </p>
      </Wrapper>
    </PageContainer>
  );
};

export { PrivacyPolicyContent };
