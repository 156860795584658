import { LayoutDefault } from '@features/layouts/layout-default';
import { SEO } from '@features/seo';
import { gatsbyPageProps } from '@infra/gatsby/types';
import { PrivacyPolicyContent } from '@organisms/privacy-policy-content';
import { StaticSiteHero } from '@organisms/static-site-hero';
import React from 'react';

const PrivacyPolicyPage = props => {
  const {
    pageContext: { locale },
    location,
  } = props;

  return (
    <LayoutDefault locale={locale} location={location}>
      <SEO
        location={location}
        title="privacyPolicyPage.metaTitle"
        description="privacyPolicyPage.metaDescription"
        lang={locale}
      />
      <StaticSiteHero title="privacyPolicyPage.privacyPolicy" />
      <PrivacyPolicyContent />
    </LayoutDefault>
  );
};

PrivacyPolicyPage.propTypes = {
  ...gatsbyPageProps,
};

export { PrivacyPolicyPage };
export default PrivacyPolicyPage;
